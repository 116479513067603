define("ln-liga-os/components/communicator/notification-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    microMenu: Ember.inject.service(),
    openNotification: function openNotification() {},
    classNames: ['communicator-notification-item-component', 'list-item'],
    classNameBindings: ['notification.context_type:has-action', 'notification.read_state:is-read', 'notification.flagged:is-flagged', 'notification.archived:is-archived', 'notification.sender_id:is-user'],
    notification: null,
    option: function option() {},
    filterIsArchive: Ember.computed.equal('filter', 'archive'),
    click: function click() {
      this.openNotification(this.notification);
    },
    clickOption: Ember._action(function (option, notification, event) {
      event.stopPropagation();
      this.option(option, notification, event);
    }),
    openMenu: Ember._action(function (component, e) {
      this.microMenu.open(e.target.id, component);
      return false;
    })
  });
});
