define("ln-liga-os/components/task-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    navigation: Ember.inject.service(),
    classNames: ['task-list-component'],
    classNameBindings: ['type', 'hasOpenPopup', 'isEmpty'],
    infoDidChange: function infoDidChange() {},
    close: function close() {},
    isEmpty: Ember.computed.empty('taskList'),
    hasOpenPopup: Ember.computed('taskListItems.@each.hasOpenPopup', function () {
      return Boolean(this.taskListItems.findBy('hasOpenPopup'));
    }),
    type: Ember.computed('title', function () {
      return this.title.toLowerCase();
    }),
    taskListItems: Ember.computed('taskList', function () {
      return (this.taskList || []).map(function (task) {
        return {
          hasOpenPopup: false,
          task: task
        };
      });
    }),
    closeInfos: Ember._action(function (item) {
      Ember.set(item, 'hasOpenPopup', false);
    }),
    openTask: Ember._action(function (item) {
      var taskId = Ember.get(item, 'task.id');
      var contextType = item.task.get('context_type');
      var resourceName = contextType === 'Project' ? 'ProjectTask' : 'EventTask';
      if (this.navigation.canOpenResource(resourceName, taskId)) {
        this.navigation.openResource(resourceName, taskId);
        this.close();
      }
    }),
    toggleInfos: Ember._action(function (item, event) {
      event.stopPropagation();
      Ember.set(item, 'hasOpenPopup', !Ember.get(item, 'hasOpenPopup'));
      this.infoDidChange(Ember.get(item, 'hasOpenPopup'));
    })
  });
});
