define("ln-liga-os/components/communicator/tab-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['communicator-tab-base-component'],
    classNameBindings: ['shouldFadein', 'isContentVisible', 'isLoading'],
    isContentVisible: false,
    shouldFadein: Ember.computed.none('isLoading'),
    updateIsInitiallyVisible: Ember.observer('isLoading', function () {
      Ember.run.scheduleOnce('afterRender', this, this._updateIsContentVisible);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.updateIsInitiallyVisible();
    },
    _updateIsContentVisible: function _updateIsContentVisible() {
      var _this = this;
      Ember.run.next(function () {
        if (Ember.isNone(_this.isLoading)) {
          _this.set('isContentVisible', true);
        } else if (!_this.isContentVisible && !_this.isLoading) {
          _this.set('isContentVisible', true);
        }
      });
    }
  });
});
