define("ln-liga-os/services/pub-sub", ["exports", "ln-liga-os/utils/inject-config", "socket.io-client"], function (_exports, _injectConfig, _socket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    config: (0, _injectConfig.default)(),
    session: Ember.inject.service(),
    subscribe: function subscribe() {
      var _this = this;
      if (this.socket) {
        this.unsubscribe();
      }
      var transports = this.get('config.ln-pub-sub-service.transports') || ['websocket', 'polling'];
      var serviceUrl = this.get('config.ln-pub-sub-service.url');
      if (!serviceUrl) {
        console.error('[PubSub] Configuration for `ln-pub-sub-service.url` missing!');
        return;
      }
      var token = this.get('session.token');
      if (!token) {
        console.error('[PubSub] Session missing!');
        return;
      }
      var _this$splitUrl = this.splitUrl(serviceUrl),
        url = _this$splitUrl.url,
        path = _this$splitUrl.path;
      this.socket = (0, _socket.default)(url, {
        path: path,
        forceNew: true,
        transports: transports
      });
      this.socket.on('connect', function () {
        _this.socket.emit('authentication', {
          token: token
        });
      });
      this.socket.on('event', function (event) {
        _this.trigger('event', event);
        if (_this.get('config.log.pubSub.events')) {
          console.log('[PubSub] event', JSON.stringify(event));
        }
      });
      this.socket.on('error', function (error) {
        return console.error('[PubSub]', error);
      });
    },
    unsubscribe: function unsubscribe() {
      if (!this.socket) {
        return;
      }
      this.socket.disconnect();
      this.socket = null;
    },
    splitUrl: function splitUrl(serviceUrl) {
      var url = new URL(serviceUrl);
      var path = url.pathname === '/' ? '/socket.io' : "".concat(url.pathname.replace(/\/$/, ''), "/socket.io");
      url.pathname = '';
      return {
        url: url.toString(),
        path: path
      };
    }
  });
});
