define("ln-liga-os/services/communicator", ["exports", "ln-liga-os/utils/create-object"], function (_exports, _createObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    api: Ember.inject.service(),
    state: Ember.inject.service(),
    session: Ember.inject.service(),
    rights: Ember.inject.service(),
    pollingIntervalTime: 60000,
    badges: null,
    isVisible: true,
    currentTab: Ember.computed('tabsConf.@each.active', function () {
      return this.tabsConf.findBy('active');
    }),
    tabsConf: Ember.computed('session.isLoggedIn', function () {
      var _this = this;
      if (!this.session.isLoggedIn || this.session.isNewUser) {
        return [];
      }
      return [{
        identifier: 'applications',
        hasRight: true
      }, {
        identifier: 'notifications'
      }, {
        identifier: 'tasks'
      }, {
        identifier: 'contacts'
      }, {
        identifier: 'absences'
      }, {
        identifier: 'birthdays'
      }, {
        identifier: 'settings',
        hasTitle: true,
        hasRight: true
      }].map(function (tab) {
        tab.target = _this;
        return (0, _createObject.default)(_this, 'communicator-tab-item', tab);
      });
    }),
    badgeNumber: Ember.computed('tabsConf.@each.{hasAccess,badge}', 'session.isLoggedIn', function () {
      if (!this.get('session.isLoggedIn')) {
        return;
      }
      var allowedTabs = this.get('tabsConf').filter(function (tab) {
        return tab.get('hasAccess');
      });
      return allowedTabs.reduce(function (a, b) {
        return a + (b.badge || 0);
      }, 0);
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.reset();
    },
    setCurrentTab: function setCurrentTab(tab) {
      var _this2 = this;
      var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      this.tabsConf.forEach(function (iTab) {
        iTab.set('rendered', false);
        iTab.set('active', false);
      });
      tab.set('active', true);
      Ember.run.later(function () {
        tab.set('rendered', true);
        _this2.trigger("opened:".concat(tab.get('identifier')));
      }, delay);
    },
    reset: function reset() {
      this.set('badges', {});
    },
    subscribe: function subscribe() {
      // TODO switch to pubSub
      this.startPolling();
    },
    unsubscribe: function unsubscribe() {
      this.stopPolling();
    },
    // TODO remove polling add Pubsub!
    startPolling: function startPolling() {
      var _this3 = this;
      this.stopPolling();
      this.updateBadges();
      var interval = setInterval(function () {
        return _this3.updateBadges();
      }, this.pollingIntervalTime);
      this.set('pollingInterval', interval);
    },
    stopPolling: function stopPolling() {
      var interval = this.pollingInterval;
      if (interval) {
        clearInterval(interval);
        this.set('pollingInterval', null);
      }
    },
    updateBadges: function updateBadges() {
      var _this4 = this;
      var playSound = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (!this.get('session.isLoggedIn')) {
        return;
      }
      var currentBadges = Object.assign({}, this.badges);
      return this.api.read('myliga', '/communicator_badges').then(function (badges) {
        if (JSON.stringify(currentBadges) === JSON.stringify(badges)) {
          return;
        }
        _this4.set('badges', badges);
      }).catch(function (err) {
        console.error(err);
      }).then(function () {
        return Ember.run.scheduleOnce('afterRender', _this4, _this4._maybeTriggerNotificationSound, playSound, currentBadges);
      }).catch(function (err) {
        console.error(err);
      });
    },
    setNotificationsOpened: Ember.on('opened:notifications', function () {
      var _this5 = this;
      if (this.get('badges.notifications') > 0) {
        this.api.update('vdc', '/notifications/opened').then(function () {
          return _this5.updateBadges(false);
        });
      }
    }),
    _maybeTriggerNotificationSound: function _maybeTriggerNotificationSound(playSound, currentBadges) {
      // Trigger notification sound if one of the badges is greater that before.
      var badges = this.badges;
      playSound = playSound && Object.keys(badges).find(function (key) {
        return !Ember.isNone(currentBadges[key]) && badges[key] > currentBadges[key];
      });
      if (playSound) {
        this.state.triggerNotificationSound();
      }
    }
  });
});
