define("ln-liga-os/controllers/password-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    token: Ember.computed.alias('model.token'),
    goToLogin: Ember._action(function () {
      this.transitionToRoute('login');
    })
  });
});
