define("ln-liga-os/components/search-input", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: 'div',
    hasValue: false,
    classNames: ['search-input-component'],
    classNameBindings: ['hasValue'],
    attributeBindings: ['placeholder', 'type'],
    change: function change() {},
    valueChange: function valueChange() {},
    placeholder: 'Search'.loc(),
    clear: Ember._action(function () {
      (0, _jquery.default)('input:first', this.element).val('').trigger('input').focus();
    }),
    input: function input(event) {
      this.set('hasValue', Boolean(event.target.value));
      this.change(event);
      this.valueChange(event.target.value);
      Ember.run.debounce(this, this.callValueDidChange, event.target.value, 250);
    },
    callValueDidChange: function callValueDidChange(value) {
      this.valueChange(value);
    }
  });
});
