define("ln-liga-os/components/password-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var conditions = [{
    label: 'At least 8 characters'.loc(),
    rule: 'lengthCondition'
  }, {
    label: 'One uppercase character'.loc(),
    rule: 'uppercaseCondition'
  }, {
    label: 'One lowercase character'.loc(),
    rule: 'lowecaseCondition'
  }, {
    label: 'One number or special character'.loc(),
    rule: 'specialCharCondition'
  }];
  var rules = conditions.map(function (_ref) {
    var rule = _ref.rule;
    return rule;
  });
  var _default = _exports.default = Ember.Component.extend({
    api: Ember.inject.service(),
    state: Ember.inject.service(),
    clientTheme: Ember.inject.service(),
    storage: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    showResetDone: false,
    password: null,
    confirmPassword: null,
    token: null,
    conditions: conditions,
    goToLogin: function goToLogin() {},
    lengthCondition: Ember.computed('password', function () {
      return (this.password || '').length >= 8;
    }),
    uppercaseCondition: Ember.computed('password', function () {
      return /[A-Z]/.test(this.password || '');
    }),
    lowecaseCondition: Ember.computed('password', function () {
      return /[a-z]/.test(this.password || '');
    }),
    specialCharCondition: Ember.computed('password', function () {
      return /[\d#$()*+,-/:;=?@[\]^_]/.test(this.password || '');
    }),
    allRulesMet: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(rules).concat([function () {
      var _this = this;
      return rules.every(function (rule) {
        return _this.get(rule);
      });
    }])),
    passwordsMatch: Ember.computed('password', 'confirmPassword', function () {
      return (this.password || '').length && this.password === this.confirmPassword;
    }),
    canResetPassword: Ember.computed('allRulesMet', 'passwordsMatch', function () {
      return !!(this.allRulesMet && this.passwordsMatch);
    }),
    resetPassword: Ember._action(function () {
      var _this2 = this;
      this.api.partialUpdate('myliga', ['security', 'password_resets'], {
        token: this.token,
        password: this.password
      }).then(function () {
        _this2.set('showResetDone', true);
      }).catch(function (error) {
        _this2.flashMessages.addErrorMessages(error);
      });
    })
  });
});
