define("ln-liga-os/components/date-time", ["exports", "moment", "ln-liga-os/utils/inject-config"], function (_exports, _moment, _injectConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    state: Ember.inject.service(),
    tagName: 'span',
    classNames: ['date-time-component'],
    classNameBindings: ['type'],
    date: null,
    type: 'date-time',
    relative: false,
    timer: null,
    config: (0, _injectConfig.default)(),
    format: Ember.computed('type', function () {
      var type = this.type;
      if (type === 'date') {
        return this.getFormat(type, 'MM-DD-YYYY');
      }
      if (type === 'date-big') {
        return this.getFormat(type, 'DD<\\s\\p\\an>MMM</\\s\\p\\an>');
      }
      if (type === 'date-time') {
        return this.getFormat(type, 'DD-MM-YYYY - HH:mm');
      }
      if (type === 'time') {
        return this.getFormat(type, 'HH:mm');
      }
      return '';
    }),
    dateFormated: Ember.computed('date', function () {
      var m = (0, _moment.default)(this.date);
      var language = this.state.getLanguage();
      if (this.date && m.isValid()) {
        if (this.relative) {
          if ((0, _moment.default)().diff(m, 'hours') < 24) {
            return m.locale(language).fromNow();
          }
        }
        return m.format(this.format);
      }
      return;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.updateRelative();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.cancelTimer();
    },
    getFormat: function getFormat(type, defaultValue) {
      var language = this.state.getLanguage();
      return this.get("config.formats.".concat(language, ".").concat(Ember.String.underscore(type))) || this.get("config.format.".concat(Ember.String.underscore(type))) || defaultValue;
    },
    updateRelative: function updateRelative() {
      var _this = this;
      if (this.isDestroyed) {
        return;
      }
      var m = (0, _moment.default)(this.date);
      if (this.relative && m.isValid()) {
        var timeoutSec = 15;
        if ((0, _moment.default)().diff(m, 'hours') > 0) {
          timeoutSec = 300;
        } else if ((0, _moment.default)().diff(m, 'days') >= 1) {
          return;
        }
        var timer = Ember.run.later(function () {
          _this.notifyPropertyChange('date');
          _this.updateRelative();
        }, timeoutSec * 1000);
        this.set('timer', timer);
      }
    },
    cancelTimer: function cancelTimer() {
      var timer = this.timer;
      if (timer) {
        Ember.run.cancel(timer);
      }
    }
  });
});
