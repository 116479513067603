define("ln-liga-os/components/communicator/base-container", ["exports", "ln-ember-toolkit/mixins/media-events", "jquery"], function (_exports, _mediaEvents, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ANIMATION_DELAY = 400;
  function touchEventXY(event) {
    var touch = event.originalEvent.touches[0] || event.originalEvent.changedTouches[0];
    return {
      x: touch.pageX || 0,
      y: touch.pageY || 0
    };
  }
  var _default = _exports.default = Ember.Component.extend(_mediaEvents.default, {
    state: Ember.inject.service(),
    session: Ember.inject.service(),
    settings: Ember.inject.service(),
    communicator: Ember.inject.service(),
    clientTheme: Ember.inject.service(),
    classNames: ['communicator-base-container-component'],
    classNameBindings: ['communicator.isVisible', 'state.communicatorIsOpen:is-open:is-closed', 'state.navigationIsOpen', 'showTabs', 'sliding', 'clientTheme.className'],
    detailsOpen: false,
    tabsAreOpen: false,
    touchStartPoint: null,
    touchMovePoint: null,
    onTabVisibilityChange: function onTabVisibilityChange() {},
    tabsConf: Ember.computed.alias('communicator.tabsConf'),
    currentTab: Ember.computed.alias('communicator.currentTab'),
    badge: Ember.computed.alias('communicator.badgeNumber'),
    slideCssProps: Ember.computed('touchStartPoint.x', 'touchMovePoint.x', function () {
      var startPoint = this.touchStartPoint;
      var movePoint = this.touchMovePoint;
      if (!startPoint || !movePoint) {
        return;
      }
      var windowWidth = (0, _jquery.default)(window).width();
      var diff = startPoint.x - movePoint.x;
      if (diff >= 0) {
        return;
      }
      var translateX = Math.abs(diff) / 2;
      if (this.get('mediaMatcher.isMobile')) {
        translateX = Math.abs(diff) - windowWidth;
      }
      return {
        transform: "translate3d(".concat(translateX, "px, 0, 0)")
      };
    }),
    showTabs: Ember.computed('tabsAreOpen', 'state.communicatorIsOpen', 'settingValues.AlwaysShowCommunicator', 'isDesktop', function () {
      return this.tabsAreOpen || this.get('state.communicatorIsOpen') || this.isDesktop && this.get('settingValues.AlwaysShowCommunicator');
    }),
    tabs: Ember.computed('tabsConf.{[],@each.hasAccess}', function () {
      return this.tabsConf.filter(function (tab) {
        return tab.get('hasAccess');
      });
    }),
    showTabsObserver: Ember.observer('showTabs', function () {
      this.onTabVisibilityChange(this.showTabs);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.state.on('hide-click-catcher', this, this.close);
      this.settings.on('settings-did-change', this, this.updateSettingValues);
      this.updateSettingValues();
    },
    close: Ember._action(function () {
      if (this.detailsOpen) {
        this.set('detailsOpen', false);
      } else {
        this._close();
      }
    }),
    forceOpenCommunicator: Ember._action(function () {
      this.toggleProperty('settingValues.AlwaysShowCommunicator');
    }),
    logout: Ember._action(function () {
      return this.session.triggerLogout();
    }),
    toggleCommunicator: Ember._action(function () {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : !this.tabsAreOpen;
      if (this.get('communicator.currentTab') || this.get('settingValues.AlwaysShowCommunicator') && this.showTabs && !this.get('state.communicatorIsOpen')) {
        return;
      }
      if (state) {
        this.openTabs();
      } else {
        this.closeTabs();
      }
    }),
    toggleTab: Ember._action(function (tab) {
      if (tab.get('active') && this.get('state.communicatorIsOpen')) {
        this.close();
      } else {
        this.open(tab);
        this.state.showClickCatcher();
      }
    }),
    willRemoveElement: function willRemoveElement() {
      this.state.off('hide-click-catcher', this, this.close);
      this.settings.off('settings-did-change', this, this.updateSettingValues);
    },
    updateSettingValues: function updateSettingValues() {
      var _this = this;
      this.settings.getValues(['AlwaysShowCommunicator']).then(function (values) {
        return _this.set('settingValues', values);
      });
    },
    open: function open() {
      var tab = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      if (!tab && !this.currentTab) {
        tab = this.set('tabs.0');
      }
      if (tab) {
        var delay = this.get('state.communicatorIsOpen') ? 0 : ANIMATION_DELAY;
        this.communicator.setCurrentTab(tab, delay);
      }
      this.state.setCommunicatorIsOpen(true);
    },
    _close: function _close() {
      this.state.setCommunicatorIsOpen(false);
      var currentTab = this.currentTab;
      if (currentTab) {
        Ember.run.later(function () {
          return currentTab.set('active', false);
        }, ANIMATION_DELAY);
      }
      this.closeTabs();
    },
    openTabs: function openTabs() {
      if (!this.tabsAreOpen && !this.isDestroyed) {
        this.set('tabsAreOpen', true);
        this.state.showClickCatcher();
      }
    },
    closeTabs: function closeTabs() {
      if (this.tabsAreOpen && !this.isDestroyed) {
        this.set('tabsAreOpen', false);
        this.state.hideClickCatcher();
      }
    },
    touchStart: function touchStart(event) {
      this.set('touchStartPoint', touchEventXY(event));
    },
    touchMove: function touchMove(event) {
      this.set('touchMovePoint', touchEventXY(event));
      this.set('sliding', true);
    },
    touchEnd: function touchEnd(event) {
      var _this2 = this;
      var startPoint = this.touchStartPoint;
      var endPoint = touchEventXY(event);
      var diff = startPoint.x - endPoint.x;
      if (diff >= 0) {
        return;
      }
      var windowWidth = (0, _jquery.default)(window).width();
      var windowPortion = this.get('mediaMatcher.isMobile') ? 4 : 7;
      this.set('touchStartPoint', null);
      this.set('touchMovePoint', null);
      if (Math.abs(diff) >= windowWidth / windowPortion) {
        this.close();
      }
      Ember.run.later(this, function () {
        return _this2.set('sliding', false);
      }, ANIMATION_DELAY);
    }
  });
});
