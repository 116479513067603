define("ln-liga-os/controllers/main", ["exports", "ln-ember-guide-tooltips/utils/guide-config"], function (_exports, _guideConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    state: Ember.inject.service(),
    clientTheme: Ember.inject.service(),
    navigation: Ember.inject.service(),
    appError: null,
    guidesActive: false,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('appError', this.get('state.appError'));
      this.state.on('app-error', function (error) {
        return _this.set('appError', error);
      }).on('app-error-reset', function () {
        return _this.set('appError', null);
      });
    },
    navigationGuides: Ember.computed('clientTheme.isDefault', function () {
      var isDefault = this.get('clientTheme.isDefault');
      var name = this.get('state.user.forename');
      var team = isDefault ? 'VDC Team' : 'LIGA-OS-Team';
      return [_guideConfig.default.create({
        target: '#communicator-user-image',
        content: 'Hi %@!<br>The communicator and your apps are now located behind your user image. <span class="foot">Yours %@</span>'.loc(name, team),
        nextOn: 'mouseenter',
        side: 'bottom',
        step: 0,
        class: 'app-switcher-guide'
      }), _guideConfig.default.create({
        target: '#communicator-user-image',
        content: '<span class="light">New:</span> Settings'.loc(),
        nextOn: 'click',
        closeSide: 'left',
        step: 1
      }), _guideConfig.default.create({
        target: '#tab-item-applications',
        content: '<span class="light">New:</span> Apps'.loc(),
        nextOn: 'click',
        closeSide: 'left',
        step: 1
      })];
    }),
    toggleGuides: Ember.observer('state.communicatorIsOpen', function (state) {
      if (this.get('state.communicatorIsOpen')) {
        state = false;
      }
      var guides = this.navigationGuides;
      Ember.set(guides, '1.isVisible', state);
      Ember.set(guides, '2.isVisible', state);
    }),
    tabVisibilityChanged: Ember._action(function (state) {
      if (!this.guidesActive) {
        return;
      }
      this.toggleGuides(state);
    })
  });
});
