define("ln-liga-os/components/communicator/tab-contacts", ["exports", "ln-liga-os/components/communicator/tab-base", "ln-ember-toolkit/mixins/media-events", "ln-ember-config-utils/utils/inject-theme"], function (_exports, _tabBase, _mediaEvents, _injectTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _tabBase.default.extend(_mediaEvents.default, {
    api: Ember.inject.service(),
    rights: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    classNames: ['communicator-tab-contacts-component'],
    // Controller content
    filter: null,
    contacts: null,
    isLoading: false,
    editMode: false,
    canEditList: false,
    activeList: null,
    searchText: '',
    showBirthday: false,
    perPage: 50,
    offset: 0,
    theme: (0, _injectTheme.default)(),
    formattedContacts: Ember.computed('contacts.[]', function () {
      var formattedContacts = [];
      if (this.filter === 'projects' || this.filter === 'lists') {
        return this.contacts;
      }

      /**
       * Sorting is done on the API side. We are just making sure each
       * contact falls into right "company group", since contacts from 1
       * company may cover 2 different pages.
       */
      (this.contacts || []).forEach(function (contact) {
        var formattedContact = {
          detailsOpen: false,
          type: 'contact',
          contact: contact
        };

        // If contact has no company, push it to the array
        if (!contact.company_name) {
          formattedContacts.push(formattedContact);
        } else {
          var companyIndex = formattedContacts.findIndex(function (_ref) {
            var text = _ref.text;
            return text === contact.company_name;
          });
          var nextSubheadIndex = formattedContacts.findIndex(function (item, index) {
            return item.type === 'subhead' && index > companyIndex;
          });
          /*
           * If there is another "group/subhead" after this company, add this contact right before that group
           * i.e. "push" it at the end of this "group".
           */
          var spliceIndex = nextSubheadIndex > -1 ? nextSubheadIndex - 1 : formattedContacts.length;

          // If the company exists in the array, add contact to that group
          if (companyIndex > -1) {
            formattedContacts.splice(spliceIndex, 0, formattedContact);
          } else {
            /**
             * Otherwise, add the company to the array, AND add the contact right after
             */
            formattedContacts.push({
              type: 'subhead',
              text: contact.company_name
            }, formattedContact);
          }
        }
      });
      return formattedContacts;
    }),
    allExpanded: Ember.computed('searchText', function () {
      return Boolean(this.searchText && this.get('searchText.length') > 0);
    }),
    resetAndLoadMore: Ember.observer('filter', function () {
      this.setProperties({
        contacts: [],
        offset: 0,
        requestedForOffset: null,
        clientsLoaded: false,
        listsLoaded: false,
        editMode: false
      });
      this.loadMore();
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.resetAndLoadMore();
      this.initRights();
    },
    actions: {
      linkTo: function linkTo(to) {
        window.location = to;
      },
      openProjectMail: function openProjectMail(project) {
        var query = {
          project: project.id,
          show_in_communicator_contact_list: true
        };
        this.api.read('myliga', ['liga3000_contacts', query]).then(function (response) {
          var addresses = (response.result || []).map(function (contact) {
            return contact.email;
          });
          window.open("mailto:".concat(addresses.join(',')));
        });
      }
    },
    addMailingList: Ember._action(function () {
      var newList = {
        id: null,
        version: null,
        name: null,
        description: null,
        email: null
      };
      this.set('activeList', newList);
      this.set('editMode', true);
    }),
    deleteMailingList: Ember._action(function () {
      var _this = this;
      var list = this.activeList;
      this.api.delete('myliga', ['email_distributors', list.id]).then(function () {
        var listToRemove = _this.contacts.find(function (listObject) {
          return listObject.list === list;
        });
        _this.contacts.removeObject(listToRemove);
        _this.set('editMode', false);
      });
    }),
    loadMore: Ember._action(function () {
      if (this.isLoading || this.requestedForOffset === this.offset) {
        return;
      }
      var filter = this.filter;
      if (filter === 'all') {
        return this.loadAll();
      } else if (filter === 'projects') {
        return this.loadProjects();
      } else if (filter === 'lists') {
        return this.loadLists();
      }
    }),
    saveMailingList: Ember._action(function () {
      var _this2 = this;
      var promise = Ember.RSVP.reject();
      if (!this.get('activeList.id')) {
        promise = this.api.create('myliga', 'email_distributors', this.activeList).then(function (list) {
          var contacts = _this2.contacts;
          var addChild = contacts.findBy('type', 'add_list');
          var position = contacts.indexOf(addChild);
          _this2.contacts.insertAt(position, {
            isOpen: false,
            type: 'mailing_list',
            list: list
          });
        });
      } else {
        var listId = this.get('activeList.id');
        promise = this.api.update('myliga', ['email_distributors', listId], this.activeList);
      }
      promise.then(function /* resp */
      () {
        _this2.set('editMode', false);
      }).catch(function (error) {
        console.error(error);
      });
    }),
    searchInputChanged: Ember._action(function (value) {
      this.set('searchText', value);
      this.resetAndLoadMore();
    }),
    toggleClient: Ember._action(function (client) {
      Ember.set(client, 'isOpen', !Ember.get(client, 'isOpen'));
      var isOpen = client.isOpen;
      var contacts = this.contacts.filter(function (contact) {
        if (!isOpen && contact.type === 'project' && contact.project.contact_group_id === client.client.id) {
          Ember.set(contact, 'isOpen', false);
          return false;
        }
        if (isOpen === false && contact.type === 'contact') {
          return false;
        }
        return true;
      });
      this.set('contacts', contacts);
      if (isOpen) {
        this.insertChilds(client, 'projects');
      }
    }),
    toggleMailingList: Ember._action(function (list) {
      this.toggleProperty('editMode');
      if (list) {
        this.set('activeList', list.list);
        this.set('activeListCopy', Ember.assign({}, list.list));
      } else if (this.activeList) {
        var prevList = this.activeList;
        var listCopy = this.activeListCopy;
        for (var prop in prevList) {
          Ember.set(prevList, prop, listCopy[prop]);
        }
      }
    }),
    toggleProject: Ember._action(function (project) {
      Ember.set(project, 'isOpen', !Ember.get(project, 'isOpen'));
      var isOpen = project.isOpen;
      var contacts = this.contacts.map(function (contact) {
        if (contact.type === 'client') {
          return contact;
        }
        if (contact !== project) {
          Ember.set(contact, 'isOpen', false);
        }
        return contact;
      }).filter(function (contact) {
        return contact.type === 'client' || contact.type === 'project';
      });
      this.set('contacts', contacts);
      if (isOpen) {
        this.insertChilds(project, 'contacts');
      }
    }),
    initRights: function initRights() {
      var _this3 = this;
      this.rights.can('UPDATE', 'EmailDistributor').then(function (permission) {
        _this3.set('canEditList', permission);
      });
    },
    addSubhead: function addSubhead(to, name) {
      to.push({
        type: 'subhead',
        text: name
      });
    },
    loadAll: function loadAll() {
      var _this4 = this;
      var query = {
        paging_offset: this.offset,
        paging_limit: this.perPage,
        search_text: this.searchText,
        show_in_communicator_contact_list: true,
        active: true
      };
      this.set('isLoading', true);
      this.api.read('myliga', ['liga3000_contacts', query]).then(function (response) {
        if (_this4.isDestroyed) {
          return;
        }
        _this4.set('requestedForOffset', _this4.offset);
        _this4.incrementProperty('offset', response.meta.count);
        _this4.contacts.pushObjects(response.result);
      }).then(function () {
        return _this4.checkListLength();
      }).finally(function () {
        if (_this4.isDestroyed) {
          return;
        }
        _this4.set('isLoading', false);
      });
    },
    loadProjects: function loadProjects() {
      var _this5 = this;
      if (this.clientsLoaded) {
        return;
      }
      this.set('isLoading', true);
      var query = {
        projects_search_text: this.searchText,
        active_project: true
      };
      var maxExpanded = 20;
      this.api.read('myliga', ['contact_groups', query]).then(function (response) {
        if (_this5.isDestroyed) {
          return;
        }
        var counter = 0;
        var clients = (response.result || []).map(function (group) {
          counter++;
          return {
            isOpen: _this5.allExpanded && counter <= maxExpanded,
            client: group,
            type: 'client',
            projects: null
          };
        });
        _this5.beginPropertyChanges();
        if (_this5.allExpanded) {
          var groupsToExpand = (clients || []).slice(0, maxExpanded);
          var groupIds = groupsToExpand.mapBy('client.id');
          _this5.loadProjectsForGroups(groupIds).then(function (projects) {
            groupsToExpand.forEach(function (group) {
              var projectsForGroup = projects.result.filterBy('contact_group_id', Ember.get(group, 'client.id'));
              group.projects = projectsForGroup.map(function (project) {
                return {
                  isOpen: false,
                  type: 'project',
                  project: project,
                  contacts: null
                };
              });
              _this5.insertChilds(group, 'projects');
            });
          });
        }
        _this5.set('requestedForOffset', _this5.offset);
        _this5.incrementProperty('offset', response.meta.count);
        _this5.set('contacts', clients);
        _this5.set('isLoading', false);
        _this5.set('clientsLoaded', true);
        _this5.endPropertyChanges();
        _this5.checkListLength();
      }).catch(function (error) {
        return console.error(error.stack || error);
      }).finally(function () {
        if (_this5.isDestroyed) {
          return;
        }
        _this5.set('isLoading', false);
      });
      return;
    },
    loadProjectsForGroups: function loadProjectsForGroups(groups) {
      var query = {
        contact_group: groups,
        search_text: this.searchText,
        active: true
      };
      return this.api.read('myliga', ['liga3000_projects', query]);
    },
    loadLists: function loadLists() {
      var _this6 = this;
      if (this.listsLoaded) {
        return;
      }
      this.set('isLoading', true);
      var contacts = this.contacts;
      this.api.read('myliga', 'email_distributors').then(function (response) {
        var addList = contacts.findBy('type', 'add_list');
        contacts.removeObject(addList);
        var lists = (response.result || []).map(function (list) {
          return {
            isOpen: false,
            type: 'mailing_list',
            list: list
          };
        });
        contacts.pushObjects(lists);
        if (_this6.canEditList) {
          contacts.pushObject({
            type: 'add_list'
          });
        }
        _this6.set('listsLoaded', true);
      }).catch(function (error) {
        _this6.flashMessages.addMessage('error', 'Unable to load email distributors');
        console.error(error);
      }).then(function () {
        return _this6.checkListLength();
      }).finally(function () {
        return _this6.set('isLoading', false);
      });
    },
    insertChilds: function insertChilds(childsFor, what) {
      var _this7 = this;
      var contacts = this.contacts;
      var loader = {
        type: 'loader'
      };
      var offset = contacts.indexOf(childsFor) + 1;
      if (childsFor[what] === null) {
        contacts.insertAt(offset, loader);
        if (what === 'projects') {
          this.loadProjectsForGroups([childsFor.client.id]).then(function (response) {
            childsFor[what] = (response.result || []).map(function (project) {
              return {
                isOpen: false,
                type: 'project',
                project: project,
                contacts: null
              };
            });
            contacts.removeObject(loader);
            _this7.insertChilds(childsFor, what);
          });
          return;
        } else if (what === 'contacts') {
          var query = {
            project: childsFor.project.id,
            show_in_communicator_contact_list: true,
            active: true
          };
          var detailsOpen = false;
          this.api.read('myliga', ['liga3000_contacts', query]).then(function (response) {
            childsFor[what] = (response.result || []).map(function (contact) {
              return {
                detailsOpen: detailsOpen,
                contact: contact,
                type: 'contact'
              };
            });
            contacts.removeObject(loader);
            _this7.insertChilds(childsFor, what);
          });
          return;
        }
      }
      (childsFor[what] || []).forEach(function (child) {
        contacts.insertAt(offset, child);
        offset++;
      });
      this.set('contacts', contacts);
    },
    checkListLength: function checkListLength() {
      Ember.run.scheduleOnce('afterRender', this, this._checkListLength);
    },
    _checkListLength: function _checkListLength() {
      var contactsLength = this.get('contacts.length');
      if (contactsLength <= 0) {
        this.formattedContacts.pushObject({
          type: 'no_results'
        });
      }
    }
  });
});
