define("ln-liga-os/services/settings", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;
  var defaultValues = {
    NotificationSound: false,
    AlwaysShowCommunicator: false,
    UserGuideSeen: 0
  };
  /**
   * @class SettingsService
   */
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    api: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    shouldReload: null,
    settings: Ember.computed('shouldReload', function () {
      var _this = this;
      if (this.shouldReload !== false) {
        var promise = this.api.read('vdc', ['current_user', 'settings']).then(function (resp) {
          return resp.result;
        }).catch(function (error) {
          _this.flashMessages.addMessage('error', 'Settings not loaded.');
          console.error(error);
        });
        return PromiseArray.create({
          promise: promise
        });
      } else {
        return Promise.resolve([]);
      }
    }),
    /**
     *
     * @memberof SettingsService
     * @instance
     *
     * @method getValue
     * @param {String} name
     * @returns {any}
     */
    getValue: function getValue(name) {
      return this.settings.then(function (settings) {
        var _ref = (settings || []).findBy('name', name) || {},
          value = _ref.value;
        if (Ember.isNone(value)) {
          return defaultValues[name];
        }
        return value;
      });
    },
    /**
     * @memberof SettingsService
     * @instance
     *
     * @method getValue
     * @param {String[]} names
     * @returns {array}
     */
    getValues: function getValues(names) {
      var _this2 = this;
      return Ember.RSVP.hash(names.reduce(function (all, name) {
        all[name] = _this2.getValue(name);
        return all;
      }, {}));
    },
    /**
     * @memberof SettingsService
     * @instance
     *
     * @method setValue
     * @param {String} name
     * @param {any} value
     * @return {Promise<Object>} setting
     */
    setValue: function setValue(name, value) {
      var _this3 = this;
      return this.settings.then(function (settings) {
        var setting = (settings || []).findBy('name', name) || _this3.createSetting(name);
        if (setting.value !== value) {
          setting.value = value;
          setting._isDirty = true;
        }
        return setting;
      });
    },
    createSetting: function createSetting(name) {
      var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var setting = {
        id: null,
        version: null,
        name: name,
        value: value,
        _isDirty: true
      };
      this.settings.pushObject(setting);
      return setting;
    },
    /**
     * @memberof SettingsService
     * @instance
     *
     * @method saveAllSettings
     * @param {String} name
     * @return {Promise<Object[]>} settings
     */
    saveAllSettings: function saveAllSettings() {
      var _this4 = this;
      var hasChangedSettings = false;
      return this.settings.then(function (settings) {
        var changedSettings = (settings || []).filterBy('_isDirty');
        hasChangedSettings = changedSettings.length > 0;
        var promises = changedSettings.map(function (setting) {
          return _this4.saveSetting(setting);
        });
        return Ember.RSVP.all(promises);
      }).then(function () {
        if (hasChangedSettings) {
          _this4.notifyPropertyChange('settings');
          _this4.trigger('settings-did-change');
        }
        return _this4.settings;
      });
    },
    /**
     * @memberof SettingsService
     * @instance
     *
     * @method saveSetting
     * @param {Object} setting
     * @return {Promise<Object>} setting
     */
    saveSetting: function saveSetting(setting) {
      var userId = this.get('session.content.userId');
      delete setting._isDirty;
      if (setting.id) {
        return this.api.update('myliga', ['users', userId, 'settings', setting.id], setting);
      }
      return this.api.create('myliga', ['users', userId, 'settings'], setting);
    },
    /**
     * @memberof SettingsService
     * @instance
     *
     * @method reloadAllSettings
     * @return {Promise<Object[]>} settings
     */
    reloadAllSettings: function reloadAllSettings() {
      this.notifyPropertyChange('shouldReload');
      return this.settings;
    }
  });
});
