define("ln-liga-os/components/navigation-item", ["exports", "ln-ember-config-utils/utils/inject-theme"], function (_exports, _injectTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    navigation: Ember.inject.service(),
    attributeBindings: ['name:ariaLabel'],
    classNames: ['navigation-item-component'],
    classNameBindings: ['isActive', 'isHighlighted', 'item.icon_url::has-no-icon'],
    item: null,
    hovereditem: null,
    higlight: function higlight() {},
    theme: (0, _injectTheme.default)(),
    iconCssProps: Ember.computed('theme.communicatorApplicationsSize', function () {
      return {
        'background-image': "url(".concat(this.get('item.icon_url'), ")")
      };
    }),
    name: Ember.computed('item.name', function () {
      return this.item.name;
    }),
    isHighlighted: Ember.computed('hoveredItem', 'item', function () {
      var item = this.item,
        hoveredItem = this.hoveredItem,
        isActive = this.isActive;
      return !hoveredItem && isActive || hoveredItem === item;
    }),
    isActive: Ember.computed('item.id', 'navigation.currentItem.id', function () {
      return this.get('item.id') === this.get('navigation.currentItem.id');
    }),
    mouseEnter: function mouseEnter() {
      this.higlight(true);
    },
    mouseLeave: function mouseLeave() {
      this.higlight(false);
    }
  });
});
