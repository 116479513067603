define("ln-liga-os/components/communicator/tab-absences", ["exports", "moment", "ln-liga-os/components/communicator/tab-base"], function (_exports, _moment, _tabBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _tabBase.default.extend({
    api: Ember.inject.service(),
    classNames: ['communicator-tab-absences-component'],
    // Controller content

    absences: null,
    formattedAbsences: null,
    filter: 'all',
    offset: 0,
    perPage: 50,
    searchText: '',
    requestedForOffset: null,
    resetState: Ember.observer('filter', function () {
      this.set('absences', []);
      this.set('offset', 0);
      this.set('requestedForOffset', null);
      this.loadMore();
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('absences', []);
      this.set('formattedAbsences', []);
      this.loadMore();
    },
    loadMore: Ember._action(function () {
      var _this = this;
      if (this.isLoading || this.requestedForOffset === this.offset) {
        return;
      }
      this.set('isLoading', true);
      var query = {
        paging_limit: this.perPage,
        paging_offset: this.offset
      };
      if (this.filter !== 'all') {
        // Turn filter into an array because `/absences` can receive multiple type params.
        query.type = this.filter.split(',');
      }
      if (this.searchText) {
        query.search_text = this.searchText;
      }
      this.api.read('myliga', ['communicator/absences', query]).then(function (response) {
        if (_this.isDestroyed) {
          return;
        }
        _this.absences.pushObjects(response.result);
        _this.set('requestedForOffset', _this.offset);
        _this.incrementProperty('offset', response.meta.count);
        _this.set('formattedAbsences', _this.formatAbsences(_this.absences));
        _this.set('isLoading', false);
      });
    }),
    searchInputChanged: Ember._action(function (value) {
      this.set('searchText', value);
      this.resetState();
    }),
    formatAbsences: function formatAbsences(absences) {
      var formattedAbsences = [{
        type: 'subhead',
        text: 'Today'.loc()
      }, {
        type: 'subhead',
        text: 'Upcoming'.loc()
      }];
      var todayAbsences = false;
      (absences || []).forEach(function (absence) {
        var start = (0, _moment.default)(absence.date_from).format('DD-MM');
        var end = (0, _moment.default)(absence.date_till).format('DD-MM');
        var text = start;
        if (start !== end) {
          text += " ".concat('to'.loc(), "  ").concat(end);
        }
        if (absence.reason) {
          text += " | ".concat(absence.reason);
        }
        var formatedAbsence = {
          detailsOpen: false,
          type: 'absence',
          contact: absence,
          reason: text,
          isIll: absence.type === 'Sickness',
          isHoliday: absence.type === 'Holiday'
        };

        // Sorting absences into Today/Upcoming
        if ((0, _moment.default)(absence.date_from).isBefore((0, _moment.default)().add(1, 'days'), 'day')) {
          var upcomingIndex = formattedAbsences.findIndex(function (_ref) {
            var text = _ref.text;
            return text === 'Upcoming'.loc();
          });
          formattedAbsences.splice(upcomingIndex, 0, formatedAbsence);
          todayAbsences = true;
        } else {
          formattedAbsences.push(formatedAbsence);
        }
      });

      // Removing trailing 'Upcoming' in case there are 0 upcoming absences.
      if (formattedAbsences[formattedAbsences.length - 1].type === 'subhead') {
        formattedAbsences.pop();
      }

      // Remove 'Today' in case there are 0 absences today.
      if (!todayAbsences) {
        formattedAbsences.shift();
      }

      // Removing default Today/Upcoming objects from array in case there are no absences at all.
      if (formattedAbsences.length === 1) {
        formattedAbsences = [];
      }
      return formattedAbsences;
    }
  });
});
