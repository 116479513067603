define("ln-liga-os/components/communicator/tab-birthdays", ["exports", "moment", "ln-liga-os/components/communicator/tab-base", "ln-ember-toolkit/mixins/media-events"], function (_exports, _moment, _tabBase, _mediaEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _tabBase.default.extend(_mediaEvents.default, {
    api: Ember.inject.service(),
    classNames: ['communicator-tab-birthdays-component'],
    filter: null,
    birthdays: null,
    isLoading: false,
    offset: 0,
    perPage: 50,
    lastSubhead: null,
    showBirthday: true,
    searchText: '',
    birthdaysLoaded: false,
    filterQuery: Ember.computed('filter', 'searchText', function () {
      var filter = this.filter;
      var fromDate = (0, _moment.default)();
      var tillDate = (0, _moment.default)().add(14, 'days');
      if (filter === 'today') {
        tillDate = fromDate;
      } else if (filter === 'coming') {
        fromDate.add(1, 'days');
      }
      return {
        active: true,
        search_text: this.searchText,
        birth_date_from: fromDate.format('MM-DD'),
        birth_date_till: tillDate.format('MM-DD'),
        sort: 'birth_date'
      };
    }),
    updateBirthdays: Ember.observer('filterQuery', function () {
      this.setProperties({
        birthdays: [],
        birthdaysLoaded: false,
        lastSubhead: null
      });
      this.loadMore();
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.updateBirthdays();
    },
    loadMore: function loadMore() {
      var _this = this;
      if (this.isLoading || this.birthdaysLoaded) {
        return;
      }
      this.set('isLoading', true);
      var query = this.filterQuery;
      this.api.read('myliga', ['liga3000_contacts', query]).then(function (response) {
        if (_this.isDestroyed) {
          return;
        }
        var birthdays = _this.parseBirthdays(response.result);
        if (birthdays.length === 0) {
          birthdays.pushObject({
            type: 'no_results'
          });
        }
        _this.birthdays.pushObjects(birthdays);
        _this.set('isLoading', false);
        _this.set('birthdaysLoaded', true);
      });
    },
    parseBirthdays: function parseBirthdays(birthdays) {
      var _this2 = this;
      var parsedBirthdays = [];
      var today = (0, _moment.default)().format('DD-MM');
      birthdays.forEach(function (birthday) {
        var subhead = 'Upcoming'.loc();
        var hasBirthday = false;
        var birthdayFormated = (0, _moment.default)(birthday.birth_date).format('DD-MM');
        if (birthdayFormated === today) {
          hasBirthday = true;
          subhead = 'Today'.loc();
        }
        if (subhead !== _this2.lastSubhead) {
          parsedBirthdays.pushObject({
            type: 'subhead',
            text: subhead
          });
          _this2.set('lastSubhead', subhead);
        }
        parsedBirthdays.pushObject({
          detailsOpen: false,
          type: 'contact',
          contact: birthday,
          birthday: birthdayFormated,
          hasBirthday: hasBirthday
        });
      });
      return parsedBirthdays;
    },
    searchInputChanged: Ember._action(function (value) {
      this.set('searchText', value);
      this.updateBirthdays();
    })
  });
});
