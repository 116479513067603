define("ln-liga-os/routes/main", ["exports", "ln-liga-os/utils/url", "ln-liga-os/utils/inject-config", "ln-liga-os/utils/format-error-message"], function (_exports, _url, _injectConfig, _formatErrorMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    apiEvents: Ember.inject.service(),
    microMenu: Ember.inject.service(),
    rights: Ember.inject.service(),
    state: Ember.inject.service(),
    clientTheme: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    navigation: Ember.inject.service(),
    pubSub: Ember.inject.service(),
    communicator: Ember.inject.service(),
    guideTooltips: Ember.inject.service(),
    settings: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    isPendingUser: false,
    previousUrl: null,
    previousUserId: null,
    config: (0, _injectConfig.default)(),
    beforeModel: function beforeModel(transition) {
      var _this = this;
      return this.session.checkAuth().catch(function (error) {
        if (error.status_code === 401) {
          _this.set('previousUrl', Ember.get(transition, 'to.params.url'));
          _this.onDidLogout(_this.get('session.content.user_id'));
        }
        throw error;
      }).then(function () {
        return _this.rights.rights.then(function (rights) {
          _this.isPendingUser = rights.length === 0;
        });
      }).then(function () {
        if (!_this.isPendingUser) {
          return _this.settings.getValue('UserGuideSeen');
        }
      }).then(function (value) {
        if (value === 0) {
          _this.set('guidesActive', true);
          _this.setupNavigationGuides();
        }
      }).catch(function (error) {
        if (_this.session.isNewUser) {
          var ssoSignupApp = _this.config.apps.find(function (app) {
            return app.slug === 'sso-signup';
          });
          if (ssoSignupApp) {
            _this.settings.set('shouldReload', false);
            _this.navigation.set('navigations', [{
              name: 'Sign up',
              url: ssoSignupApp.url
            }]);
            return;
          }
        }
        throw error;
      });
    },
    model: function model(_ref) {
      var _this2 = this;
      var url = _ref.url;
      var previousUrl = this.popPreviousUrl();
      if (previousUrl) {
        url = previousUrl;
        // '!' means no app selected
      } else if (!url || url === '!' && !this.session.isNewUser) {
        url = this.getLastVisitedUrl() || '!';
      }
      try {
        // Replace encoded pipes.
        (0, _url.replaceLocationHash)(/%7C/g, '|');
      } catch (error) {
        // mute
      }
      if (this.isPendingUser) {
        this.state.triggerAppError({
          description: 'Please wait for your application to be reviewed',
          title: 'User review pending'
        });
        return;
      }
      return Promise.resolve(this.session.content ? this.state.loadUser() : undefined).then(function () {
        return _this2.navigation.loadNavigations();
      }).then(function () {
        var appUrl = _this2.decodeRoute(url);
        var currentRouteUrl = _this2.encodeRoute(_this2.get('navigation.currentUrl'));
        if (currentRouteUrl && currentRouteUrl !== '!' && currentRouteUrl !== url) {
          _this2.navigation.openUrl(appUrl);
        }
        if (appUrl) {
          if (!_this2.navigation.setUrl(appUrl)) {
            return;
          }

          // upon the initial login into the app, the `activate` hook wasn't called yet
          _this2.onUrlDidChange(appUrl);
        } else {
          _this2.navigation.openFirstItem();
        }
        _this2.navigation.loadedAppCheck();
        _this2.state.setAppTitle(_this2.get('navigation.currentItem.name'));
      }).catch(function (error) {
        var errorMessage = (0, _formatErrorMessage.default)(error, null);
        _this2.flashMessages.addMessage('error', errorMessage);
        console.error(error);
      });
    },
    activate: function activate() {
      this.pubSub.subscribe();
      this.navigation.on('url-did-change', this, this.onUrlDidChange);
      this.session.on('did-logout', this, this.onDidLogout);
      this.state.on('language-did-change', this, this.onLanguageDidChange);
      this.apiEvents.on('error-response', this, this.onApiErrorResponse);
      if (!this.session.isNewUser) {
        this.communicator.subscribe();
      }
    },
    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.microMenu.enable();
    },
    deactivate: function deactivate() {
      this.pubSub.unsubscribe();
      this.communicator.unsubscribe();
      this.navigation.off('url-did-change', this, this.onUrlDidChange);
      this.session.off('did-logout', this, this.onDidLogout);
      this.state.off('language-did-change', this, this.onLanguageDidChange);
      this.apiEvents.off('error-response', this, this.onApiErrorResponse);
      document.body.classList.remove('supports-micro-menu');
    },
    setupNavigationGuides: function setupNavigationGuides() {
      var guides = this.navigationGuides;
      Ember.run.scheduleOnce('afterRender', this, this._setupNavigationGuides, guides);
    },
    _setupNavigationGuides: function _setupNavigationGuides(guides) {
      this.guideTooltips.setGuides(guides, 'navigation-guides');
      this.guideTooltips.on('guides-finished', this, this.teardownNavigationGuides);
    },
    teardownNavigationGuides: function teardownNavigationGuides(label) {
      var _this3 = this;
      if (label !== 'navigation-guides') {
        return;
      }
      return this.settings.setValue('UserGuideSeen', 1).then(function (setting) {
        return _this3.settings.saveSetting(setting);
      });
    },
    encodeRoute: function encodeRoute(url) {
      url = (0, _url.encodeHashPart)(url);
      url = this.navigation.addSlug(url);
      return url;
    },
    decodeRoute: function decodeRoute(url) {
      if (!url) {
        return null;
      }
      url = this.navigation.removeSlug(url);
      return (0, _url.decodeHashPart)(url);
    },
    onDidLogout: function onDidLogout(userId) {
      this.set('previousUserId', userId);
      this.state.reset();
      this.router.transitionTo('login');
    },
    onUrlDidChange: function onUrlDidChange(url) {
      var encodedUrl = this.encodeRoute(url);
      this.replaceWith("/".concat(encodedUrl));
      this.saveLastVisitedUrl(encodedUrl);
    },
    getLastVisitedUrl: function getLastVisitedUrl() {
      return sessionStorage.getItem('last-app-url');
    },
    saveLastVisitedUrl: function saveLastVisitedUrl(url) {
      sessionStorage.setItem('last-app-url', url);
    },
    onLanguageDidChange: function onLanguageDidChange() {
      window.location.reload();
    },
    popPreviousUrl: function popPreviousUrl() {
      var url = this.previousUrl;
      var userId = this.previousUserId;
      this.setProperties({
        previousUrl: null,
        previousUserId: null
      });

      // If previous request was without a session or from the same user.
      if (url && (!userId || userId === this.session.userId)) {
        return url;
      }
    },
    onApiErrorResponse: function onApiErrorResponse(response) {
      if (this.session.isLoggedIn && response.status === 401) {
        this.session.triggerLogout();
      }
    }
  });
});
