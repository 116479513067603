define("ln-liga-os/services/client-theme", ["exports", "ln-ember-config-utils/utils/inject-theme", "ln-liga-os/config/environment", "ln-liga-os/utils/inject-config"], function (_exports, _injectTheme, _environment, _injectConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function assetURL(path) {
    var _location = location,
      protocol = _location.protocol,
      host = _location.host;
    var appVersion = _environment.default.APP.version,
      rootURL = _environment.default.rootURL;
    return path ? "".concat(protocol, "//").concat(host).concat(rootURL, "assets/").concat(path, "?v=").concat(appVersion) : null;
  }
  var _default = _exports.default = Ember.Service.extend({
    api: Ember.inject.service(),
    theme: (0, _injectTheme.default)(),
    config: (0, _injectConfig.default)(),
    themeData: null,
    defaultData: null,
    support: Ember.computed('data.type', function () {
      var type = this.get('data.type').toLowerCase();
      return this.get("config.support.".concat(type)) || this.get('config.support.default') || {};
    }),
    isDefault: Ember.computed('data.type', function () {
      return ['Dark', 'Light', 'AMG'].includes(this.get('data.type'));
    }),
    data: Ember.computed('themeData', 'defaultData', function () {
      var _this = this;
      var defaultData = Ember.assign({}, this.defaultData);
      if (!this.themeData) {
        return defaultData;
      }
      var themeData = Object.keys(this.themeData).reduce(function (themeData, key) {
        var value = _this.get("themeData.".concat(key));
        if (!Ember.isNone(value)) {
          themeData[key] = _this.get("themeData.".concat(key));
        }
        return themeData;
      }, {});
      return Ember.assign({}, defaultData, themeData);
    }),
    className: Ember.computed('data.type', function () {
      return "theme-".concat(this.get('data.type').toLowerCase());
    }),
    style: Ember.computed('data', function () {
      var _ref = this.data || {},
        bgURL = _ref.background_image.preview_url,
        logoURL = _ref.logo_image.preview_url;
      if (this.get('data.type') === 'LIGANOVA') {
        logoURL = assetURL('themes/liganova/images/logo-ligaos.svg');
      }
      if (this.get('data.type') === 'Dark') {
        logoURL = assetURL('images/svg/vdc-software-logo-white.svg');
      }
      if (this.get('data.type') === 'AMG') {
        return;
      }
      var styles = [];
      if (bgURL) {
        styles.push("\n        .background-container, .startup-mask-component {\n          background-image: url('".concat(bgURL, "') !important;\n        }\n      "));
      }
      styles.push("\n      .logo {\n        background-image: url('".concat(logoURL, "') !important;\n      }\n    "));
      return Ember.String.htmlSafe(styles.join('\n'));
    }),
    init: function init() {
      this._super();
      this.set('defaultData', {
        title: this.get('config.clientTheme.defaultTitle') || 'LIGAOS',
        type: this.get('config.clientTheme.defaultType') || 'LIGANOVA',
        background_image: {
          preview_url: null
        },
        logo_image: {
          preview_url: assetURL(this.get('theme.themeLogoUrl'))
        }
      });
    },
    favicon: Ember.computed('data.type', function () {
      var name = this.get('data.type').toLowerCase();
      return ['Dark', 'Light'].includes(name) ? 'default' : name;
    }),
    load: function load() {
      var _this2 = this;
      var _location2 = location,
        search = _location2.search,
        hostname = _location2.hostname;
      var _ref2 = search.match(/domain=([^&]+)/) || [],
        _ref3 = _slicedToArray(_ref2, 2),
        domain = _ref3[1];
      return this.api.read('myliga', ['themes', {
        domain: domain || hostname,
        paging_limit: 1
      }]).then(function (_ref4) {
        var _ref4$result = _slicedToArray(_ref4.result, 1),
          theme = _ref4$result[0];
        return theme;
      }).catch(function (error) {
        return console.error("GET /themes: ".concat(error.message || error.responseText || error));
      }).then(function (theme) {
        return _this2.set('themeData', theme);
      }).then(function () {
        return _this2.setup();
      });
    },
    setup: function setup() {
      var favicon = this.favicon;
      var faviconElement = document.querySelector('#favicon');
      faviconElement && faviconElement.setAttribute('href', assetURL("images/favicons/".concat(favicon, ".ico")));
    }
  });
});
